<template>
  <div>
    <!-- Settings Popup 设备详情弹窗 -->
    <div class="popup-wrap">
      <div class="popup-header">
        Current Settings
        <img src="@/assets/icons/icon_close.png" alt="" @click="onClickClose"/>
      </div>

      <div class="setting-model">
        <button
            class="model-button"
            :class="activeMode === 0 ? 'model-button-active' : ''"
            style="margin-right:8px;"
            @click="onClickSelectModel(0)"
        >
          Hub
        </button>
        <button
            class="model-button"
            :class="activeMode === 1 ? 'model-button-active' : ''"
            style="margin-left:8px;"
            @click="onClickSelectModel(1)"
        >
          Device
        </button>
      </div>

      <div>
        <div v-show="activeMode === 0" class="popup-content">
          <div class="device">
            <div class="device-name">OMNI Hub</div>
            <div class="device-info-row">
              <div class="device-info-title">Device Model</div>
              <div class="device-info-value">OMNI iKhub</div>
            </div>
            <div class="device-info-row">
              <div class="device-info-title">S/N</div>
              <div class="device-info-value">
                {{ macAddressText }}
              </div>
            </div>
          </div>

          <!--          <div class="preheat" v-if="hubSetting.isSupportPreheat">-->
          <div class="preheat" v-if="false">
            <div class="preheat-title">
              Preheat
              <img
                  v-if="hubSetting.isSupportPreheat"
                  src="@/assets/icons/preheat_on.png"
              />
            </div>

            <div class="device-info-row">
              <div class="device-info-title">Time:</div>
              <div class="device-info-value">
                {{ (hubSetting.preheatTime / 1000).toFixed(1) }}s
              </div>
            </div>
            <div class="device-info-row">
              <div class="device-info-title">Power:</div>
              <div class="device-info-value">
                {{
                  (
                      Math.pow(hubSetting.preheatVoltage / 1000, 2) / rValue
                  ).toFixed(2)
                }}w
              </div>
            </div>
          </div>
          <div v-if="false" class="preheat">
            <div class="preheat-title">
              Preheat
              <img src="@/assets/icons/preheat_off.png"/>
            </div>
          </div>
          <!--<div style="font-size: 16px;">-->
          <!--编号:-->
          <!--</div>-->
          <!--<div style="font-size: 16px;" v-html="deviceId.toString2()"></div>-->

          <div class="voltage">
            <div class="voltage-title">Power Curve</div>
            <!--<div class="voltage-mode">Curve 2</div>-->
            <!-- 温度曲线组件插入位置 -->
            <!-- 曲线1 -->
            <div
                id="hubCurve"
                ref="hubCurve"
                class="voltage-one"
                style="width:100%;height:calc(22vh)"
            ></div>
          </div>

          <div class="voltage-three">
            <div class="voltage-number" v-for="index of 8" :key="index">
              {{ index }}s
            </div>
          </div>
          <!--<div class="sync-button" @click="syncToApp">Close</div>-->
        </div>

        <div v-show="activeMode === 1" class="popup-content">
          <div v-if="loadDeviceSettingStatus === 1">
            <div class="device">
              <div class="device-name">Device Info</div>

              <div class="device-info-row">
                <div class="device-info-title">Device Model</div>
                <div class="device-info-value">
                  {{ getInsertDeviceName() }}
                </div>
              </div>

              <div class="device-info-row">
                <div class="device-info-title">ID</div>
                <div class="device-info-value">
                  {{ deviceIdentify }}
                </div>
              </div>

              <div class="device-info-row">
                <div class="device-info-title">Device Type</div>
                <div class="device-info-value">
                  {{ deviceType }}
                </div>
              </div>
              <div class="device-info-row">
                <div class="device-info-title">Resistance</div>
                <div class="device-info-value">{{ resistance }} Ω</div>
              </div>

              <div class="device-info-row">
                <div class="device-info-title">Prod. Time</div>
                <div class="device-info-value">
                  {{ productTime }}
                </div>
              </div>

              <div class="device-info-row">
                <div class="device-info-title">Brand</div>
                <div class="device-info-value">
                  iKrusher
                </div>
              </div>


            </div>
            <!--            <div class="preheat" v-if="writerSetting.isSupportPreheat">-->
            <div class="preheat" v-if="false">
              <div class="preheat-title">
                Preheat
                <img
                    v-if="writerSetting.isSupportPreheat"
                    src="@/assets/icons/preheat_on.png"
                />
              </div>

              <div class="device-info-row">
                <div class="device-info-title">Time:</div>
                <div class="device-info-value">
                  {{ (writerSetting.preheatTime / 1000).toFixed(1) }}s
                </div>
              </div>
              <div class="device-info-row">
                <div class="device-info-title">Power:</div>
                <div class="device-info-value">
                  {{
                    (
                        Math.pow(writerSetting.preheatVoltage / 1000, 2) /
                        parseFloat(resistance)
                    ).toFixed(2)
                  }}w
                </div>
              </div>
            </div>
            <!--            <div v-else class="preheat">-->
            <div v-if="false" class="preheat">
              <div class="preheat-title">
                Preheat
                <img src="@/assets/icons/preheat_off.png"/>
              </div>
            </div>

            <div class="voltage">
              <div class="voltage-title">Power Curve</div>
              <div class="d019-curve" v-if="isD019">
                Curve
                <div :style="levelClasses"></div>
              </div>
              <!-- 温度曲线组件插入位置 -->
              <div
                  id="deviceCurve"
                  ref="deviceCurve"
                  class="voltage-one"
                  style="width:100%;height:calc(16vh)"
              />
            </div>

            <div class="voltage-three">
              <div class="voltage-number" v-for="index of 8" :key="index">
                {{ index }}s
              </div>
            </div>
            <!--<div class="sync-button" @click="readDeviceSetting">-->
            <!--Device sync to HUB-->
            <!--</div>-->
            <van-button :color="syncButtonColor" style="border-radius: 8px;width:100%"
                @click="onApplyToHome">Apply to home default
            </van-button>
          </div>

          <div v-else-if="loadDeviceSettingStatus === 0" class="error-info">
            <div class="device-error-msg">{{ errorMessage }}</div>
            <div class="try-again" @click="reloadDeviceSetting">
              Try again
            </div>
          </div>

          <div v-else class="popup-content info-device-load">
            <van-loading size="60px"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { initCanvasRatioWidthSize } from "@/util";
import { WriterSetting } from "@/bluetooth/BluetoothData";
import bluetoothRepository from "@/bluetooth/BluetoothRepository";
import { chartConfig, d019ChartConfig, voltageData } from "@/config/echarts-data";
import { calcMaxPower, calcVoltageFloorInt } from "@/util/utils";

const graphic = require("echarts/lib/util/graphic");
export default {
  name: "CurrentSetting",
  //components: { DeviceSetting, HubSetting },
  props: {
    rValue: {
      type: Number,
      default: 1.0
    },
    maxValue: {
      type: Number,
      default: 12
    }
  },
  data() {
    //黄 0
    //白 1
    return {
      hubCurveChart: null,
      hubChartOption: chartConfig(),
      deviceCurveChart: null,
      deviceCurveOption: chartConfig(),
      activeMode: 0,
      showProgress: true,
      errorMessage: "",
      hubSetting: this.getHubSetting(),
      loadDeviceSettingStatus: 2,
      timeoutId: -1,
      curveLevelColor: ["green", "rgba(255, 140, 0, 1)", "red"],
      curveLevelColorLinearGradient: [
        ["rgba(0, 255, 0, 1)", "rgba(0, 255, 0, 0.2)"],
        ["rgba(255, 140, 0, 1)", "rgba(255, 140, 0, 0.2)"],
        ["rgba(255, 0, 0, 1)", "rgba(255, 0, 0, 0.2)"]
      ],
      curveColor: "#86A1A9",
      curveColorLinearGradient: [
        "rgba(192, 222, 226, 1)",
        "rgba(246, 250, 250, 1)"
      ],
      currentDeviceLevel: 0
    };
  },
  created() {
  },
  mounted() {
    //console.log("mounted");
    if (this.$refs.hubCurve) {
      this.hubCurveChart = this.$echarts.init(this.$refs.hubCurve);
      window.addEventListener("resize", this.onWindowSizeChanged);
    }
    this.refreshHubCurve();
  },
  destroyed() {
    window.removeEventListener("resize", this.onWindowSizeChanged);
  },
  computed: {
    ...mapState(["disposableHubSetting"]),
    ...mapState("bluetooth", [
      "isConnected",
      "macAddress",
      "cartridgeFlag",
      "deviceId",
      "writerSetting",
      "level",
      "insertDeviceName"
    ]),
    ...mapState("theme", ["syncButtonColor"]),

    levelClasses() {
      let background = "";
      console.log(`读取的档位 = ${this.level}`);
      if (this.level === 0) {
        background = "yellow";
      } else if (this.level === 1) {
        background = "white";
      }
      return {
        width: "20px",
        height: "20px",
        borderRadius: "50%",
        "margin-left": "10px",
        border: "1px #999 solid",
        background
      };
    },
    macAddressText() {
      return (this.macAddress && this.macAddress.replace(/:/g, "")) || "";
    },
    deviceType() {
      if (this.deviceId) {
        switch (this.deviceId.type) {
          case 1:
            return "Disposable";
          case 3:
            return "Pod Battery";
          case 5:
            return "510 Battery";
        }

        return "";
      }
      return "";
    },
    isD019() {
      if (this.isConnected) {
        if (this.deviceId) {
          const insertDeviceName = this.insertDeviceName;
          if (
              insertDeviceName === "PORTO" ||
              insertDeviceName === "D022" ||
              insertDeviceName === "D023"
          ) {
            return true;
          }
        }
      }
      return false;
    },

    isSupportLevel() {
      if (this.isConnected) {
        if (this.deviceId) {
          const insertDeviceName = this.insertDeviceName;
          if (
              insertDeviceName === "510 Pen" ||
              insertDeviceName === "Lipstick" ||
              insertDeviceName === "PORTO"
          ) {
            return true;
          }
        }
      }
      return false;
    },
    brandName() {
      if (this.deviceId) {
        switch (this.deviceId.productManufacture) {
          case 0:
            return "AVD";
          case 1:
            return "iKrusher";
          case 2:
            return "Unicore";
        }
      }
      return "";
    },

    deviceIdentify() {
      if (this.deviceId) {
        return this.deviceId.deviceNumberHex;
      }
      return "";
    },
    resistance() {
      if (this.deviceId) {
        const r = this.deviceId.atomizedR;
        if (this.getInsertDeviceName() == "NORD") {
          return "1.2";
        }
        return `${(r / 10).toFixed(1)}`;
      }
      return "";
    },
    productTime() {
      if (this.deviceId) {
        const year = this.deviceId.productYear;
        const week = this.deviceId.productWeek;

        return `week ${week.toString(16)}, 20${year.toString(16)}`;
      }
      return "";
    }
  },
  watch: {
    // async activeMode(newValue, oldValue) {
    //   if (newValue === 1) {
    //     this.reloadDeviceSetting();
    //   } else {
    //     this.showProgress = true;
    //     store.commit(StoreType.COMMIT_TYPE.STATE_CURRENT_WRITER_SETTING, null);
    //   }
    // }
    deviceId(value) {
      if (value) {
        if (value.type === 5) {
          //电池修改颜色
          const level = ((value.productManufacture >> 4) & 0x0f) - 1;
          if (level < 3) {
            this.curveColor = this.curveLevelColor[level];
            this.curveColorLinearGradient = this.curveLevelColorLinearGradient[
                level
                ];
            this.currentDeviceLevel = level;
            return;
          }
        }
      } //为空了
      this.curveColor = this.defaultCurveColor; //默认颜色
      this.curveColorLinearGradient = [
        this.defaultCurveLineColor[0],
        this.defaultCurveLineColor[1]
      ]; //默认渐变颜色
    }
  },
  methods: {
    ...mapGetters("bluetooth", ["getHubSetting", "getInsertDeviceName"]),
    ...mapState("theme", {
      defaultCurveColor: state => state.curveColor,
      defaultCurveLineColor: state => state.curveLineColor
    }),
    onApplyToHome() {
      const writerSetting = new WriterSetting();
      Object.assign(writerSetting, this.writerSetting);
      writerSetting.diyVoltage = writerSetting.diyVoltage.slice(0, 8);
      this.$emit("onApplyToHome", writerSetting);
    },
    async onClickSelectModel(value) {
      this.activeMode = value;
      if (value === 1) {
        //如果是device的时候，转圈加载完在显示
        this.reloadDeviceSetting();
      }
    },

    onClickClose() {
      this.$emit("close");
      this.$nextTick(() => {
        setTimeout(() => {
          this.activeMode = 0;
        }, 100);
      });
    },

    //刷新echarts
    refreshHubCurve() {
      this.hubSetting.power = this.hubSetting.diyVoltage.map(
          item => Math.pow(item, 2) / 1000000 / parseFloat(this.rValue)
      );
      this.preheatPower = parseInt(
          Math.pow(this.hubSetting.preheatVoltage, 2) /
          1000000 /
          parseFloat(this.rValue)
      );


      const limitMaxVoltage = calcVoltageFloorInt(this.maxValue, this.rValue);
      const maxPower = calcMaxPower(limitMaxVoltage, this.rValue);

      const max = this.hubSetting.power.reduce((a, b) => Math.max(a, b));
      this.hubChartOption.yAxis.max = maxPower;
      this.hubChartOption.series[0].data = this.hubSetting.power;
      this.hubCurveChart.setOption(this.hubChartOption, true);
    },

    refreshDeviceCurve() {
      if (this.$refs.deviceCurve) {
        this.deviceCurveChart = this.$echarts.init(this.$refs.deviceCurve);
      }

      const limitMaxVoltage = calcVoltageFloorInt(this.maxValue, this.rValue);
      const maxPower = calcMaxPower(limitMaxVoltage, this.rValue);
      if (this.insertDeviceName === 'PORTO' && this.deviceId.productWeek === 82
          && this.deviceId.productYear === 35
          && this.level === 1) {
        //判断是否d019是烧录错的
        const errorVoltages = [3200, 3100, 2900, 2800, 2700, 2600, 2500, 2400, 2400, 2400];
        if (this.writerSetting.diyVoltage.toString() === errorVoltages.toString()) {
          this.writerSetting.diyVoltage = [3200, 3100, 3000, 2900, 2800, 2700, 2600, 2400, 2400, 2400];
        }
      }
      //

      this.writerSetting.power = this.writerSetting.diyVoltage.map(
          item => {
            const power = Math.pow(item, 2) / 1000000 / parseFloat(this.rValue);
            return power >= maxPower ? maxPower : power;
          }
      );
      this.preheatPower = parseInt(
          Math.pow(this.writerSetting.preheatVoltage, 2) /
          1000000 /
          parseFloat(this.rValue)
      );

      const deviceId = this.deviceId;
      if (deviceId) {
        if (deviceId.type === 5) {
          //电池修改颜色
          const level = ((deviceId.productManufacture >> 4) & 0x0f) - 1;
          if (level < 3) {
            this.curveColor = this.curveLevelColor[level];
            this.curveColorLinearGradient = this.curveLevelColorLinearGradient[
                level
                ];
            this.currentDeviceLevel = level;
          }
        } else {
          this.curveColor = this.defaultCurveColor; //默认颜色
          this.curveColorLinearGradient = [
            this.defaultCurveLineColor[0],
            this.defaultCurveLineColor[1]
          ]; //默认渐变颜色
        }

        let options = chartConfig();
        if (this.isD019) {
          const level = this.level;
          // console.log(`当前d019的档位 => ${level}`)
          options = d019ChartConfig(this.isD019 ? "D019" : "", level + 1);
        }

        const max = this.writerSetting.power.reduce((a, b) => Math.max(a, b));

        options.yAxis.max = Math.max(this.maxValue, max);
        options.series[0].data = this.writerSetting.power;

        this.deviceCurveChart.setOption(options, true);
      }
    },

    async resetToHubSetting() {
      this.$emit("resetToHubSetting");
    },

    async reloadDeviceSetting() {
      //如果是device的时候，转圈加载完在显示
      try {
        clearTimeout(this.timeoutId);
        this.timeoutId = setTimeout(() => {
          this.errorMessage = "Timeout";
          this.loadDeviceSettingStatus = 0;
        }, 5000);
        this.loadDeviceSettingStatus = 2; //转圈加载

        await bluetoothRepository.queryWriter();
        clearTimeout(this.timeoutId);
        this.loadDeviceSettingStatus = 1; //加载成功

        this.$nextTick(() => {
          this.refreshDeviceCurve();
        });
      } catch (e) {
        clearTimeout(this.timeoutId);

        // console.log(e)
        if (typeof e === "string") {
          this.errorMessage = e;
        } else {
          this.errorMessage = "Read device info failure!";
        }
        this.loadDeviceSettingStatus = 0; //加载失败
      }
    },

    onWindowSizeChanged() {
      if (this.$refs.hubCurve) {
        this.$nextTick(() => {
          this.hubCurveChart.resize();
        });
      }
      if (this.$refs.deviceCurve) {
        this.$nextTick(() => {
          this.deviceCurveChart.resize();
        });
      }
    }
  }
};
</script>

<style lang="less" scoped>
/deep/ .van-tabs__wrap {
  height: 1.6rem;
  margin-bottom: 20px;
}

/deep/ .van-tabs__nav--card {
  height: 1.6rem;
  border: none;
  background: transparent !important;
}

/deep/ .van-tab {
  margin: 0 5px !important;
  color: #939598;
  background: #e8eff2;
  border-right: none !important;
  border-bottom: none !important;
  border-radius: 5px !important;

  span {
    font-size: 18px;
  }
}

/deep/ .van-tab--active {
  background: #86a1a9 !important;
}

.popup-close {
  width: 100%;
  text-align: center;
  position: fixed;
  top: -45px;

  img {
    width: 27px;
    height: 27px;
  }
}

.popup-wrap {
  position: relative;
  overflow: hidden;
  height: 90vh;

  .popup-header {
    z-index: 2000;
    background: white;
    position: sticky;
    top: 0;
    font-size: 21px;
    font-weight: bold;
    color: #6d6e71;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 20px 20px 20px;

    img {
      position: absolute;
      right: 10px;
      width: 35px;
      height: 35px;
      border: 10px solid transparent;
      background-clip: padding-box;
    }
  }

  .setting-model {
    margin-bottom: 16px;
    display: flex;
    align-items: center;

    .model-button {
      height: 60px;
      margin: 0 20px;
      background: var(--tabButtonInactiveColor);
      color: #939598;
      border-radius: 6px;
      font-size: 18px;
      font-weight: bold;
      width: 50%;
    }

    .model-button-active {
      background: var(--tabButtonActiveColor);
      color: #ffffff;
    }
  }
}

.popup-content {
  height: calc(90vh - 150px);
  display: flex;
  flex-direction: column;
  padding: 0 20px 0 20px;
  margin-bottom: 20px;
  overflow-y: auto;

  .title {
    margin-bottom: 15px;
    font-size: 16px;
    font-weight: normal;
    color: #a7a9ac;
  }

  .info {
    font-size: 18px;
    font-weight: normal;
    color: #808285;
    display: flex;
    align-items: center;
    margin-bottom: 33px;

    img {
      margin-left: auto;
      margin-right: 3px;
      width: 19px;
      height: 19px;
    }
  }

  .info-device-load {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 60px 0;
  }

  .error-info {
    height: 50vh;
    color: #86a1a9;
    display: flex;
    text-align: center;
    justify-content: center;
    flex-direction: column;
    justify-items: center;
    margin-top: 10px;
    font-size: 20px;
    font-weight: 400;
    color: #555555;

    .try-again {
      margin: 0 auto;
      font-size: 16px;
      width: 120px;
      height: 40px;
      line-height: 40px;
      color: #fff;
      border: 1px solid #f1edff;
      background: #86a1a9;
      border-radius: 20px;
      margin-top: 2vh;
    }
  }

  .device {
    margin-bottom: 17px;

    .device-name {
      font-size: 21px;
      font-weight: bold;
      color: #6d6e71;
    }

    .device-address {
      font-size: 18px;
      font-weight: normal;
      color: #939598;
    }
  }

  .voltage {
    .voltage-title {
      font-size: 21px;
      font-weight: bold;
      color: #6d6e71;
    }

    .voltage-mode {
      margin-bottom: 24px;
      font-size: 18px;
      font-weight: normal;
      color: #939598;
    }
  }

  .preheat {
    margin-bottom: 15px;

    .preheat-title {
      font-size: 21px;
      font-weight: bold;
      color: #6d6e71;
      display: flex;

      img {
        width: 21px;
        height: 21px;
        margin-left: auto;
      }
    }

    .preheat-time {
      margin-top: 20px;
      font-size: 18px;
      font-weight: normal;
      color: #939598;
    }

    .preheat-voltage {
      margin-top: 17px;
      font-size: 18px;
      font-weight: normal;
      color: #939598;
    }
  }

  .device-info-row {
    display: flex;
    margin-top: 10px;
    font-size: 18px;

    .place-holder {
      width: calc(50%);
    }

    .device-info-title {
      font-weight: normal;
      color: #939598;
    }

    .device-info-value {
      margin-left: auto;
      font-weight: normal;
      color: #6d6e71;
    }
  }

  .sync-button {
    height: 45px !important;
  }

  .voltage-three {
    display: flex;
    width: 100%;
    margin-bottom: 20px;

    div {
      margin: 0 auto;
      font-size: 13px;
      font-weight: 400;
      color: #999999;
    }
  }
}

.popup-button {
  margin: 62px 25px 27px 25px;
  height: 45px;
  line-height: 45px;
  background: #86a1a9;
  border-radius: 8px;
  text-align: center;
  font-size: 21px;
  font-weight: bold;
  color: #ffffff;
}

.d019-curve {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  color: #6d6e71;
}
</style>
